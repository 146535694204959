@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800&display=swap');  

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  background: linear-gradient(130deg, #C9F4FE, #C9F1C6, #FFFEE3, #FED3F1, #C4D5FE, #FFB3C8);
  background-size: 500% 500%;
  animation: background-gradient 60s linear infinite;
  width: 100vw;
  position: fixed;
  height: 100%;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  font-size: 1rem;
}

[contenteditable] {
  outline: 0px solid transparent;
}

input {
  outline: 0px solid transparent;
  border: 0px solid transparent;
}

::placeholder {
  color: #00000040;
  opacity: 1;
}

.login:hover, .completed-button:hover {
  background-color: #00000020 !important;
}

.popup.override .custom-space-out {
  display: flex;
  justify-content: center;
  gap: 1.5em;
}

.popup.completed .popup-title {
  font-size: min(3.4em, 8vw);
}

.popup.completed .popup-body li {
  font-size: min(1.3em, 4.5vw);
}

.popup.completed li:last-child {
  margin-top: 1vh;
}

.popup.completed h2 {
  font-size: 1.5em;
  margin-top: 1em;
  color: #00000050;
  font-weight: 500;
}

.popup.completed .popup-body {
  overflow-y: scroll;
}

.popup.completed ul {
  list-style: none;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.popup.completed li {
  width: 96%;
  padding: 2% 3%;
  border: 0.09em solid #81818180;
  border-radius: 0.8em;
  color: #818181B0;
  font-size: 1.3em;
  text-align: left;
  font-weight: 500;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  margin-bottom: .4em;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
}

.popup.completed button {
  border: none;
  padding: 0;
  background: none;
  color: inherit;
}

@keyframes background-gradient {
  0% { background-position: 0 0; }
  50% { background-position: 100% 100%; }
  100% { background-position: 0% 0%; }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* hide all scrollbars */
* {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}